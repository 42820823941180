<template>
  <div>
    <vuetify-audio
      v-if="transformerElement(element).element_type == 'Audio'"
      :file="transformerElement(element).element_data"
      color="success"
    ></vuetify-audio>
    <v-img
      style="max-width: 60%; max-height: 450px"
      v-if="transformerElement(element).element_type == 'Image'"
      :src="transformerElement(element).element_data"
      contain
    ></v-img>
    <div
      v-if="transformerElement(element).element_type == 'Video'"
      class="videoUpload"
    >
      <b-embed
        type="iframe"
        aspect="16by9"
        :src="transformerElement(element).element_data"
      ></b-embed>
    </div>
    <!--    <Editor style="max-height: 450px; overflow: auto;" v-if="transformerElement(element).element_type =='Post'" :value="transformerElement(element).element_data" :disabled="true"></Editor>-->
    <div
      id="elementPost"
      style="font-family: 'Montserrat', sans-serif !important"
      v-if="transformerElement(element).element_type === 'Post'"
      v-html="transformerElement(element).element_data"
    ></div>
    <template v-if="transformerElement(element).element_type == 'Album'">
      <v-row v-if="transformerElement(element).element_data.length">
        <template
          v-for="(photo, index) in transformerElement(element).element_data"
        >
          <v-col cols="3" :key="index">
            <v-img contain :src="photo.url"></v-img>
          </v-col>
        </template>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  name: "ElementType",
  components: {
    VuetifyAudio: () => import("vuetify-audio"),
    // Editor: () => import('@/components/ckeditor5/Editor.vue'),
  },
  props: ["element"],
  data() {
    return {};
  },
  created() {
    // console.log(this.element);
  },
  methods: {
    transformerElement(element) {
      let data = {
        element_data: null,
        element_type: null,
        element_id: null,
      };
      if (element) {
        let check = this.checkTypeElement(element.type);
        data.element_type = this.checkTypeElement(element.type);
        data.element_id = element.id;
        if (check == "Post") {
          data.element_data = element.content;
        } else if (check == "Album") {
          data.element_data = element.photos;
        } else {
          data.element_data = element.media_path;
        }
      }
      return data;
    },
    checkTypeElement(type) {
      let type_txt;
      switch (type) {
        case 1:
          type_txt = "Post";
          break;
        case 2:
          type_txt = "Image";
          break;
        case 3:
          type_txt = "";
          break;
        case 4:
          type_txt = "Embed Youtube";
          break;
        case 5:
          type_txt = "Video";
          break;
        case 6:
          type_txt = "Post";
          break;
        case 7:
          type_txt = "Audio";
          break;
        case 8:
          type_txt = "PDF_file";
          break;
        case 9:
          type_txt = "Album";
          break;
        default:
          type_txt = null;
          break;
      }
      return type_txt;
    },
  },
};
</script>

<style lang="css" scoped>
.videoUpload {
  max-width: 60%;
}
#elementPost {
  max-width: 90%;
  border: 1px solid #d1d3e0;
  border-radius: 5px;
  padding-left: 10px;
}
</style>
